import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';

function Footer() {

  const [showTopBtn, setShowTopBtn] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true)
      } else {
        setShowTopBtn(false)
      }
    })
  })

  function goTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <Container fluid >
      <div className="socials">
        <ul>
          <li> <a href="https://www.facebook.com/AmaraSalonMadison?mibextid=LQQJ4d" target='blank' > <i className='fab fa-facebook-f' style={{ color: 'blue' }}  ></i> </a> </li>
          <li> <a href="https://www.instagram.com/amara.beauty.salon?igsh=MTMyMmQ4c3JnMWwwNQ%3D%3D&utm_source=qr" target='blank' > <i className='fab fa-brands fa-instagram'style={{ color: 'black' }}  ></i> </a> </li>
          <li> <a href="https://wa.link/cwu06x" target='blank' > <i className='fab fa-brands fa-whatsapp'  style={{ color: 'green' }}  ></i> </a> </li>
          <li> <a href="https://www.tiktok.com/@amara_beauty.salon?_t=8lIzoOBA0iZ&_r=1" target='blank' > <i className='fab fa-brands fa-tiktok' style={{ color: 'black' }}  ></i> </a> </li>
        </ul>
      </div>
      <div className="copyright"> &copy; 2024 Amara Beauty Salón. Todos los derechos reservados.
        <p>
          Desarrollado por:  
          <a href="https://caizaguanokevin-897c6.web.app/" target="_blank"  class="enlace__destacado">
                    Ing. Kevin Caizaguano &#128072;
                </a>
          </p>
      </div>
      {
        showTopBtn && (<div className='go-top' onClick={goTop} ></div>)
      }
    </Container>
  )
}

export default Footer