import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

function ConsultationDialog({ show, handleClose }) {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();


    const handleConsult = () => {

        if (phoneNumber.trim() === '') {
            setPhoneNumberError('Por favor ingrese un número de teléfono');
            return;
        }
        isChecked? navigate(`/reservationStylist/${phoneNumber }`) : navigate(`/reservationView/${phoneNumber}`)
    };


    const handleCheckChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleChangePhoneNumber = (e) => {
        const { value } = e.target;
        setPhoneNumber(value);
        setPhoneNumberError(value.trim() === '' ? 'Por favor ingrese un número de teléfono' : '');
    };

    const handleModalClose = () => {
        // Restablecer el estado del número de teléfono al cerrar el modal
        setPhoneNumber('');
        handleClose();
    };

    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-dialog-scrollable"
        >
            <Modal.Header closeButton>
                <Modal.Title>Consulta de citas </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Ingrese el número de télefono para consultar sus citas
                <input
                    type="number"
                    className="form-control"
                    placeholder="Número de teléfono"
                    value={phoneNumber}
                    onChange={handleChangePhoneNumber}
                />
                {phoneNumberError && <div className="text-danger">{phoneNumberError}</div>}


                <br />

                <Form>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Soy estilista"
                        checked={isChecked}
                        onChange={handleCheckChange}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    Cerrar
                </Button>

                <Button className="btn btn-primary" onClick={handleConsult}>
                    Consultar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConsultationDialog