import React from 'react'

function HeaderA() {
  return (
    <div>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand color-App ">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
          </li>
        </ul>
        <ul className="navbar-nav" >
          <li className="nav-item text-center">
            <strong>   AMARA BEAUTY SALÓN </strong>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>

        </ul>
      </nav>
      {/* /.navbar */}

    </div>
  )
}

export default HeaderA