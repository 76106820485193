import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { alert, alertResponseYesNo, alertTime } from '../../functions/helperFunctions';
import { deleteReserve, patchReserve } from '../../services/apiReserve';
import { getClients, patchClientService } from '../../services/apiClient';



function CitasDialog({ show, handleClose, data }) {

    const [id, setId] = useState('');
    const [client, setClient] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [service, setService] = useState('');
    const [phoneClient, setPhoneClient] = useState(0);
    const [lstClients, setLstClients] = useState([]);
    const [load, setLoad] = useState(true);
    const [estate, setEstate] = useState(false);



    useEffect(() => {
        if (show && data != null) {
            setId(data.data.id)
            setClient(data.data.cliente.nombre)
            setStart(data.start)
            setEnd(data.end)
            setService(data.data.servicio.nombre)
            setPhoneClient(data.data.cliente.telefono)
            data.data.estado === "finalizado" ? setEstate(false):setEstate(true)
        } else {
            setId('')
            setClient('')
            setStart('')
            setEnd('')
            setService('')
            setPhoneClient(0)
        }
    }, [show, data]);

    useEffect( () => {
            const cl = async() => {
                setLstClients( await getClients())
            }
            cl();
    }, [] )


    const deleteReservation = async () => {
        setLoad(false);
        const response = await alertResponseYesNo('Advertencia', '¿Está seguro de que desea eliminar la cita?', 'question');

        if (response.isConfirmed) {
            try {
                const deleteResponse = await deleteReserve(id);
                if (deleteResponse.statusText === "OK") {
                    handleClose('accept')
                    setLoad(true)
                } else {
                    alert('Error', 'Intente de nuevo', 'error');
                }
            } catch (error) {
                console.error('Error al eliminar la reserva:', error);
                alert('Error', 'Intente de nuevo', 'error');
            }
        }
        setLoad(true)

    }

    const endReservation = async () =>{
        setLoad(false);
        const response = await alertResponseYesNo('Advertencia', '¿Desea finalizar la cita?', 'question');
        
        if (response.isConfirmed) {
            try {
                let client = lstClients.find(c => c.telefono === parseInt(phoneClient))
                let bodyCli = {'servicio': (client.servicio +1) }
                await patchClientService(client.id,bodyCli)
                let body = {"estado":"finalizado"}
                const update = await patchReserve(id,body);
                if (update.statusText === "OK") {
                    handleClose('accept')
                    setLoad(true)
                } else {
                    alert('Error', 'Intente de nuevo', 'error');
                }
            } catch (error) {
                console.error('Error al eliminar la reserva:', error);
                alert('Error', 'Intente de nuevo', 'error');
            }
        }
        setLoad(true)
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title> Citas  </Modal.Title>
            </Modal.Header>
            {
                load ?
                    (
                        <>
                            <Modal.Body>
                                <p> <b> Cliente : </b> {client} </p>
                                <p> <b> Fecha : </b> {new Date(start).toLocaleDateString()} </p>
                                <p> <b> Hora : </b>  {new Date(start).toLocaleTimeString()} - {new Date(end).toLocaleTimeString()} </p>
                                <p> <b> Servicio : </b>  {service} </p>
                            </Modal.Body>

                            <Modal.Footer>
                            {    estate ? (<Button variant="success" onClick={() => endReservation()}  > Finalizar</Button>):('')  }
                            {  estate ? (<Button variant="danger" onClick={() => deleteReservation()}> Eliminar cita</Button>):('') }
                            </Modal.Footer>

                        </>


                    ) : (
                        <div className="spinner text-center">
                            <i className="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    )
            }

        </Modal>
    )
}

export default CitasDialog