import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';

import  presentation from '../assets/images/img-hero2.jpg'
import aboutAmara  from '../assets/images/about_amara.jpg'

const now = 95;

function About() {
    return (
        <section id='about' className='block about-block' >
            <Container fluid >
                <div className="title-holder">
                    <h2>  ! BIENVENIDOS ! </h2>
                    <div className="subtitle">  <strong> SOMOS AMARA BEAUTTY SALÓN </strong> </div>
                </div>
                <Row>
                    <Col   md={6}   >
                    <Image src={aboutAmara} fluid thumbnail  alt='Salón de belleza'    />
                    </Col>
                    <Col  sm={6} >
                        <p>¡Nos complace enormemente darte la bienvenida a nuestro mundo en <strong>Amara Beauty Salon</strong>! Aquí, cada detalle ha sido cuidadosamente diseñado pensando en ti.</p>
                        <p>  Fundar Amara Beauty Salon ha sido un sueño hecho realidad para mí, y estoy emocionada de compartirlo contigo. Nuestro compromiso es ofrecerte no solo servicios de la más alta calidad, sino también una atención excepcional. </p>
                        <p>Gracias por confiar en nosotros y elegirnos para ser parte de tu cuidado y belleza. Esperamos con ansias conocerte y hacer de tu visita una experiencia verdaderamente inolvidable.</p>
                        <p> <strong>Con cariño,Paola</strong></p>
                        <div className="progress-block">
                            <h4>CUIDADO</h4>
                            <ProgressBar now={now}  animated  label={`${now}%`} />
                        </div>
                        <div className="progress-block">
                            <h4>ASESORÍA</h4>
                            <ProgressBar now={now} animated  label={`${now}%`} />
                        </div>
                        <div className="progress-block">
                            <h4>BELLEZA</h4>
                            <ProgressBar now={now} animated label={`${now}%`} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default About