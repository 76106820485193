import React from 'react'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { formatNumber } from '../functions/helperFunctions';

const ServiceItem = props => {
    let { foto, costo, nombre, tiempo , oferta} = props.data;
    const data = {
        servicio: nombre,
        estilista: ''
    }

    return (
        <Card className='p-0 overflow-hidden h-10 shadow text-center ' style={{ height: '450px' }} >

            <div className='overflow-hidden rouded p-0 bg-light' >
                <Card.Img variant="top" src={foto} style={{ height: '200px', objectFit: 'cover' }} alt="Service" />
            </div>
            <Card.Body >
                <h1 className='display-6 text-center '  > {formatNumber(costo)} </h1>
                <Card.Text className='big-text' >{nombre} </Card.Text>
                <Card.Text className='big-text'>
                    <strong>Tiempo:</strong> {tiempo > 1 ? tiempo + ' horas' : tiempo + ' hora'  } 

                </Card.Text>
                <div className="text-center">
                    <Link className="btn btn-primary" to={`/reserve/${JSON.stringify(data)}`} >
                        Reservar Cita
                    </Link>
                </div>
                {
                oferta ? (
                    <div className="alert alert-danger text-center p-0 m-1" role="alert">
                    <strong >¡ {oferta} !</strong>
                </div>
                ):('')
            }

            </Card.Body>
        </Card>
    )
}

export default ServiceItem