import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/images/Amara Logo 2.webp';
import ConsultationDialog from './Dialogs/ConsultationDialog';
import { Link } from 'react-router-dom';

function Header() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#">
            <img
              src={logo}
              width="90"
              height="auto"
              className="align-top"
              alt="Amara Beauty Salon"
              style={{ objectFit: "cover" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link   href="#home">Inicio</Nav.Link>
              <Nav.Link   href="#about">Nosotros</Nav.Link>
              <Nav.Link   href="#services">Servicios</Nav.Link>
              <Nav.Link   href="#teams">Equipo</Nav.Link>
              <Nav.Link   href="#testimonials">Clientes</Nav.Link>
              <Nav.Link   href="#contact">Contacto</Nav.Link>
              <Nav.Link onClick={handleShow}>
                <i className='fa-solid fa-calendar-days'></i>
              </Nav.Link>
              <Nav.Link as={Link} to="/login">
                <i className='fa-solid fa-user'></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ConsultationDialog show={show} handleClose={handleClose} />
    </>
  );
}

export default Header;
