import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import logo from '../assets/images/Amara Logo 1.png'
import manos from '../assets/images/manos.png'
import Word_carousel from '../components/Word_carousel.jsx';
import { Link } from 'react-router-dom';
import { getServices } from '../services/apiServices';
import left from '../assets/images/leftAmara.webp'
import rightTop from '../assets/images/unas2.webp'
import salon from '../assets/images/salon.webp'



function Hero() {

    const [services, setServices] = useState([]);

    useEffect(() => {

        const getData = async () => {
            try {
                const response = await getServices();
                const servicesArray = Object.values(response);
                const wordsArray = []
                servicesArray.map(service => {
                    wordsArray.push(service.nombre)
                })
                setServices(wordsArray);
            } catch (error) {
                console.error('Error al obtener los servicios:', error);
            }
        }

        getData();
    }, [])


    return (
        <section id='home' className='hero-block d-flex align-items-stretch' style={{ overflowX: 'hidden' }} >
            <Row className='mx-0 w-100'>
                <Col sm={12} lg={4}  className='p-0 d-none d-lg-block'>
                    <Image src={left} fluid className='w-100 h-100' />
                </Col>
                <Col sm={12} lg={4} className='p-0'style={{ backgroundColor: '#EDEDEB' }} >
                    <Row className='flex-column h-100'>
                        <Col className="text-center">
                            <Image src={logo} fluid />
                        </Col>
                        <Col className="text-center">
                            <h2 className='title-principal' > <d> OBTÉN EL LOOK PERFECTO</d> </h2>
                            <Link className='btn btn-primary text-center' to="/services" > Agendar Ahora </Link>
                        </Col>
                        <Col className='flex-grow-1'>
                            <Image src={manos} fluid />
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} lg={4} className='p-0 d-none d-lg-block'>
                    <Row sm={12} lg={6}>
                        <Image src={rightTop} fluid className='w-100 h-100' />
                    </Row>
                    <Row sm={12} lg={6}>
                        <Image src={salon} fluid className='w-100 h-100' />
                    </Row>
                </Col>
                <Word_carousel words={services} />
            </Row>



        </section>
    );
}

export default Hero