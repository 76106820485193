import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}citas.json`;

export const getFilterDateNumber = async (date, numberStylist) => {
    try {
        const url = `${API_URL}?orderBy="start"&startAt="${date}T00:00:00"&endAt="${date}T23:59:59"`
        const response = await axios.get(url);
        const reponseArray = Object.values(response.data);
        return reponseArray.filter(c => c.estilista.telefono === numberStylist);
    } catch (error) {
        throw error;
    }
};


export const getFilterDate = async (starDate, endDate) => {
    try {
        let end = endDate.replace(/T00:00:00$/, "T23:59:59");
        const url = `${API_URL}?orderBy="start"&startAt="${starDate}"&endAt="${end}"&print=pretty`
        const response = await axios.get(url);
        const reponseArray = Object.values(response.data);
        //reponseArray.filter(c => c.estilista.telefono === numberStylist)
        return reponseArray;
    } catch (error) {
        throw error;
    }
};


export const getReservations = async () => {
    try {
        const response = await axios.get(API_URL);
        for (let key in response.data) {
            response.data[key].id = key;
        }
        const responseArray = Object.values(response.data)
        return responseArray;
    } catch (error) {
        throw error;
    }
};



export const postReserve = async (data) => {
    try {
        const response = await axios.post(API_URL, data);
        return response;
    } catch (error) {
        throw error;
    }
};



export const deleteReserve = async (id) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}citas/${id}.json`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const patchReserve = async (id,value) => {
    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}citas/${id}.json`,value);
        return response;
    } catch (error) {
        throw error;
    }
}