import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Reserve from './components/Reserve';
import ReservationView from './components/ReservationView';
import Login from './components/admin/Login';
import Services from './components/Services';
import Diary from './components/admin/Diary';
import Admin from './components/admin/Admin';
import ServicesA from './components/admin/pages/ServicesA'
import Dashboard from './components/admin/pages/Dashboard'
import CategoriesA from './components/admin/pages/CategoryA'
import StylistA from './components/admin/pages/StylistA.jsx'
import ClientesA from './components/admin/pages/ClientsA.jsx'
import Pague404 from './components/Pague404.jsx'
import ReservationSylist from './components/ReservationStylist.jsx'

function App() {


  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/reserve/:data" element={<Reserve />} />
          <Route path="/reservationView/:number" element={<ReservationView />} />
          <Route path="/login" element={<Login />} />
          <Route path="/services" element={<Services />} />
          <Route path="/reservationStylist/:number" element={<ReservationSylist />} />
          <Route path="/diary/:number" element={<Diary />} />
          <Route path="/admin" element={<Admin />} >
            <Route path='servicesA' element={<ServicesA />}/>
            <Route path='dashboard' element={<Dashboard />}/>
            <Route path='categoriesA' element={<CategoriesA />}/>
            <Route path='stylistA' element={<StylistA />}/>
            <Route path='clientesA' element={<ClientesA />}/>
            <Route path='servicesA' element={<ServicesA />}/>
            <Route path="diary/:number" element={<Diary />} />
          </Route>
          <Route path='*' element={<Pague404 />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
