import React, { useEffect, useState } from 'react'
import NavBarSimple from './NavBarSimple'
import Footer from './Footer'
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { getReservations, deleteReserve } from '../services/apiReserve';
import { alert, alertResponseYesNo, formatNumber } from '../functions/helperFunctions';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

function ReservationView() {
  const [reservations, setReservation] = useState([])
  const [cliente, setCliente] = useState('')
  const [lengthReservation, setlengthReservation] = useState(false)

  const data = useParams();

  useEffect(() => {
    apiReservations()
  }, []);

  const apiReservations = async () => {
    try {
      const response = await getReservations();
      const filterReservartion = response.filter(r => r.cliente.telefono ===  parseInt(data.number) && r.estado === 'agendado')
      .sort((a, b) => new Date(b.start) - new Date(a.start));
      if (filterReservartion.length > 0) {
        setCliente(filterReservartion[0].cliente.nombre);
        setlengthReservation(true)
      }
      setReservation(filterReservartion);
    } catch (error) {
      console.error('Error al obtener los servicios:', error);
    }
  };

  const cancelReservation = async (reservation) => {
    const response = await alertResponseYesNo('Advertencia', '¿Está seguro de que desea eliminar su cita?', 'question');

    if (response.isConfirmed) {
      try {
        const deleteResponse = await deleteReserve(reservation.id);
        if (deleteResponse.statusText === "OK") {
          apiReservations();
        } else {
          alert('Error', 'Intente de nuevo', 'error');
        }
      } catch (error) {
        console.error('Error al eliminar la reserva:', error);
        alert('Error', 'Intente de nuevo', 'error');
      }
    }
  }


  return (
    <>
      <NavBarSimple />
      <section>
        <div className="title-holder">
          <h2>Bienvenido <strong>{cliente} </strong> </h2>
          <div className="subtitle">Estas son tus citas agendadas </div>
        </div>
        {
          lengthReservation ? (
            <Table responsive striped bordered hover shadow-sm rounded   >
              <thead>
                <tr>
                  <th>Servicio</th>
                  <th>Estilista</th>
                  <th>Fecha</th>
                  <th>Hora</th>
                  <th>Costo</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {
                  reservations.map((reservation, i) => {
                    return (
                      <tr key={i} >
                        <td> {reservation.servicio.nombre} </td>
                        <td> {reservation.estilista.nombre} </td>
                        <td> {dayjs(reservation.start).format('DD MMMM YYYY')} </td>
                        <td> {dayjs(reservation.start).format('HH:mm A')} </td>
                        <td> {formatNumber(reservation.servicio.costo)} </td>
                        <td> <Button onClick={() => cancelReservation(reservation)}> Cancelar </Button>  </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          ) : (<p className='text-center' > No tiene reservaciones o escriba correctamente su número</p>)
        }

      </section>
      <footer id='footer' className='footer' >
        <Footer />
      </footer>

    </>
  )
}

export default ReservationView