import React from 'react'
import Diary from '../components/admin/Diary';
import NavBarSimple from './NavBarSimple';




function ReservationStylist() {
    return (
        <>
            <NavBarSimple />
            <section>
                <Diary />
            </section>
        </>
    )
}

export default ReservationStylist