
import React, { useState, useEffect } from 'react';
import '../components/World_carousel.css'; // Archivo CSS para el estilo del slider
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Word_carousel = ({ words }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // Intervalo de tiempo ajustado a 3 segundos para un movimiento más lento

    return () => clearInterval(interval);
  }, [words]);

  const sliderStyle = {
    transform: `translateX(-${(currentIndex % words.length) * (100 / words.length)}%)`,
    transition: 'transform 1s ease-in-out', // Agregar una transición suave para el movimiento
  };

  return (
    <section  >
      <Row className='mx-0'>
          <Col>
            <div className="infinite-word-slider ">
              <div className="slider-content " style={sliderStyle}>
                {words.map((word, index) => (
                  <div key={index} className="slide">
                    <strong > {word.toUpperCase()} </strong>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
    </section>

  );
};

export default Word_carousel