import React, { useEffect, useState } from 'react'
import { deleteEstylist, getEstylist } from '../../../services/apiEstylis';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import StylistDIalog from '../../Dialogs/StylistDIalog';
import { alertResponseYesNo, alertTime, deleteFile } from '../../../functions/helperFunctions';
import MUIDataTable from 'mui-datatables';



function StylistA() {

  const [stylist, setStylist] = useState([]);
  
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);



  const columns = [
    { name: "nombre", label: "Nombre", options: { filter: true, sort: true, } },
    { name: "horario", label: "Horario" },
    { name: "descripcion", label: "Descripción" },
    { name: "telefono", label: "teléfono" },
    {
      name: "acciones",
      label: "ACCIÓN",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (value) => {
          return (
            <>
              <Button variant="warning" onClick={() => openDialog(stylist[value])} >
                <i className='fa-solid fa-edit'  ></i>
              </Button>
              &nbsp;
              <Button variant="danger" >
                <i className='fa-solid fa-trash' onClick={() => deleteData(stylist[value])}  ></i>
              </Button>
            </>
          );
        },
      },
    },
  ];


  const handleClose = (action) => {
    setShow(false)
    if (action === 'accept') {
      getData()
    }
  };

  const openDialog = (dataStylist) => {
    setShow(true)
    setData(dataStylist)
  }

  useEffect(() => {
    getData()

  }, []);

  const getData = async () => {
    try {
      const response = await getEstylist();
      setStylist(response)

    } catch (error) {
      console.log('Error inesperado ', error)
    }
  }


  const deleteData = async (data) => {

    const response = await alertResponseYesNo('Advertencia', '¿Está seguro de que desea eliminar un estilista?', 'question');

    if (response.isConfirmed) {
      try {
        const deleteResponse = await deleteEstylist(data.id);
        await deleteFile(data.imagen)
        if (deleteResponse.statusText === "OK") {
          alertTime('OK', 'Estilista elminado con éxito', 'success')
          getData()
        } else {
          alert('Error', 'Intente de nuevo', 'error');
        }
      } catch (error) {
        console.error('Error al eliminar la estilista:', error);
        alert('Error', 'Intente de nuevo', 'error');
      }
    }


  }

  return (
    <>
      <Container>
        <h2>Listado de estilistas </h2>
      </Container>
      <Container className='m-3'>
        <Row>
          <Col sm={12} lg={4} >
            <Button className='w-100' onClick={() => openDialog(null)} >
              <i className='fas fa-plus-circle' > </i> Agregar
            </Button>
          </Col >
        </Row>
      </Container>
      <Container>
          <MUIDataTable
            data={stylist}
            columns={columns}
            options={{
              selectableRows: "none",
              download: false,
              viewColumns: false,
              responsive: 'standard',
              elevation: 5
            }}
          />

      </Container>


      < StylistDIalog show={show} handleClose={handleClose} data={data} />
    </>

  )
}

export default StylistA