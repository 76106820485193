import React, { useEffect, useState } from 'react'

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import { alert, alertTime, deleteFile, uploadFile, validateImage } from '../../functions/helperFunctions';
import { postEstylist, updateEstylist } from '../../services/apiEstylis';


function StylistDIalog({ show, handleClose, data }) {

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [hour, setHour] = useState('');
    const [description, setDesciption] = useState('');


    //VARIABLES PARA EL MANEJO DE LAS IMAGENES
    const [image, setImage] = useState(null);
    const [update, setUpdate] = useState(false);
    const [changeImage, setChangeImage] = useState('')
    const [file, setFile] = useState(null);
    const [load, setLoad] = useState(true);

    useEffect(() => {
        if (show && data != null) {
            setName(data.nombre)
            setPhone(data.telefono)
            setHour(data.horario)
            setDesciption(data.descripcion)
            setImage(data.imagen)
            setChangeImage(data.imagen)
            setUpdate(true)
            setId(data.id)
        } else {
            setName('')
            setPhone('')
            setHour('')
            setDesciption('')
            setFile(null)
            setImage(null)
            setUpdate(false)
            setChangeImage('')
            setId('')
        }
    }, [show, data]);


    const uploadPhoto = async (e) => {
        const result = await validateImage(e)
        if (result) {
            const file = e.target.files[0];
            setFile(result)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            if (file) {
                reader.readAsDataURL(file);
            }
            setFile(file)
        }
    }


    const saveData = async (e) => {

        e.preventDefault();
        setLoad(false)
        try {

            //PROCESO DE GUARDADO DE IMAGEN
            var url = changeImage;
            if (file != null) {
                if (!update) {
                    url = await uploadFile(file, 'stylists')
                } else {
                    url = await uploadFile(file, 'stylists')
                    await deleteFile(changeImage)
                }
            }

            //CAPTURA DE DATOS
            const data = {
                nombre: name,
                telefono: parseInt(phone),
                horario: hour,
                descripcion: description,
                imagen: url
            }

            var res ;
            update? res = await updateEstylist(id,data) : res = await postEstylist(data);

            if (res) {
                alertTime('Exito', 'Estilista ingresado correctamente', 'success')
                setLoad(true)
                handleClose('accept')
            }

        } catch (error) {
            alert('Error: Intente otra vez')
            setLoad(true)

        }

    }


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title> {data != null ? 'Editar ' : 'Nuevo '} estilista  </Modal.Title>
            </Modal.Header>
            {
                load ?
                    (
                        <Modal.Body>
                            <Form onSubmit={(e) => saveData(e)} >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        autoFocus
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Teléfono</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required

                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Horario</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={hour}
                                        onChange={(e) => setHour(e.target.value)}
                                        required

                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Descipción</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={description}
                                        onChange={(e) => setDesciption(e.target.value)}

                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Foto</Form.Label>
                                    <Form.Control
                                        type='file'

                                        onChange={e => uploadPhoto(e)}
                                    />
                                </Form.Group>
                                {image && (
                                    <div>
                                        <p>Imagen cargada:</p>
                                        <img src={image} alt="Imagen cargada" style={{ maxWidth: '100%' }} />
                                    </div>
                                )}

                                <Modal.Footer>
                                    <Button variant="primary" type='submit'>
                                        Guardar
                                    </Button>
                                    &nbsp;
                                    <Button variant="secondary" type='button' onClick={() => handleClose('cancel')}>
                                        Cancelar
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal.Body>

                    ) : (
                        <div className="spinner text-center">
                            <i className="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    )
            }

        </Modal>
    )
}

export default StylistDIalog