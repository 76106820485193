import urlAxios from 'axios';

export const login = async (data) => {
    try {
        const response = await urlAxios.post(process.env.REACT_APP_API_URL_LOGIN, data);
        localStorage.setItem('token', response.data.idToken)
        localStorage.setItem('refreshToken', response.data.refreshToken)
        return response
    } catch (error) {
        throw error;
    }
};


export const userLogin = async (body) => {
    try {
        const response = await urlAxios.post(process.env.REACT_APP_API_URL_GET_USER, body);
        return response

    } catch (error) {
        throw error;
    }
};