import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/images/Amara Logo 2.webp';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import ConsultationDialog from './Dialogs/ConsultationDialog';



function NavBarSimple() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Navbar expand="lg" className=" color-App "   >
                <Container>
                    <Navbar.Brand href="#">
                        <Link to="/" >
                            <img
                                src={logo}
                                width="90"
                                height="auto"
                                className=" align-top align-top "
                                alt="Amara Beauty Salon"
                                style={{ objectFit: "cover" }}
                            />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" >
                        <Nav>
                            <Nav.Link  ><Link to="/" style={{ color: 'black' }}  >  <strong>Inicio</strong> </Link></Nav.Link>
                            <Nav.Link href="https://www.facebook.com/AmaraSalonMadison?mibextid=LQQJ4d" target='blank'><i className='fab fa-facebook-f' style={{ color: 'blue' }}  ></i></Nav.Link>
                            <Nav.Link href="https://www.instagram.com/amara.beauty.salon?igsh=MTMyMmQ4c3JnMWwwNQ%3D%3D&utm_source=qr" target='blank' ><i className='fa-brands fa-instagram' style={{ color: 'black' }}  ></i></Nav.Link>
                            <Nav.Link href="https://wa.link/cwu06x" target='blank'><i className='fa-brands fa-whatsapp' style={{ color: 'green' }}  ></i></Nav.Link>
                            <Nav.Link href="https://www.tiktok.com/@amara_beauty.salon?_t=8lIzoOBA0iZ&_r=1" target='blank'><i className='fa-brands fa-tiktok' style={{ color: 'black' }}  ></i></Nav.Link>
                            <Nav.Link onClick={handleShow}>
                                <i className='fa-solid fa-calendar-days'></i>
                            </Nav.Link>
                            <Nav.Link as={Link} to="/login">
                                <i className='fa-solid fa-user'></i>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <ConsultationDialog show={show} handleClose={handleClose} />

        </>

    )
}

export default NavBarSimple