import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import { getEstylist } from '../services/apiEstylis';
import { Link } from 'react-router-dom';


function Teams() {
    const [estylists, setEstylists] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getEstylist(); // Llamar a la función getEstylist para obtener los estilistas
                setEstylists(response); // Almacenar los estilistas en el estado
            } catch (error) {
                console.error('Error al obtener los estilistas:', error);
            }
        };

        fetchData();
    }, []);


    return (
        <section id='teams' className='block teams-block' >
            <Container fluid>
                <div className="title-holder">
                    <h2>NUESTRO EQUIPO</h2>
                    <div className="subtitle"> Nuestro equipo de profesionales son altamente calificados que inspira confianza y seriedad </div>
                </div>
                <Row>
                    {
                        estylists.map(estylist => {
                            return (
                                <Col sm={3} key={estylist.id} className='text-center '>
                                    <div className="image"  >
                                        <Image src={estylist.imagen} thumbnail />
                                        <div className="overlay">
                                            <a href="" className='btn designation' > <h3>AGENDAR AHORA</h3>  </a>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h3> {estylist.nombre} </h3>
                                        <span className='designation' >  Estilista </span>
                                        <p>
                                            <strong>Horario : </strong> {estylist.horario}
                                            <br />
                                            <small>{estylist.descripcion ? estylist.descripcion : "----"}</small>
                                        </p>

                                        <Link className="btn btn-primary" to={`/reserve/${JSON.stringify({servicio: '', estilista:estylist.id})}`} >
                                            Reservar Cita
                                        </Link>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </section>
    )
}

export default Teams