import React, { useEffect, useState } from 'react'
import { getClients } from '../../../services/apiClient';
import { getServices } from '../../../services/apiServices'
import { getEstylist } from '../../../services/apiEstylis'
import { getFilterDate } from '../../../services/apiReserve'
import Table from 'react-bootstrap/Table';
import dayjs from 'dayjs';
import Chart from "react-apexcharts";
import { formatDate, formatNumber } from '../../../functions/helperFunctions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Estilos predeterminados
import 'react-date-range/dist/theme/default.css'; // Tema predeterminado




function Dashboard() {

  const [clients, setClients] = useState([]);
  const [services, setServices] = useState([]);
  const [stylist, setStylist] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
  const [endtDate, setEndtDate] = useState(new Date());
  const [filterReservations, setFilterReservations] = useState([]);
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const [total, setTotal] = useState(0);
  const [load, setLoad] = useState(true);


  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const handleSelect = ranges => {

    const { startDate, endDate } = ranges.selection;
    setSelectionRange(ranges.selection);
    setStartDate(startDate)
    setEndtDate(endDate)

  };


  const today = dayjs();


  useEffect(() => {
    loadData()
  }, [])



  const loadData = async () => {
    try {
      setClients(await getClients())
      setServices(await getServices())
      setStylist(await getEstylist())
      filterData()
      
    } catch (error) {
      console.log('Error al obtener los datos : ', error)
    }
  }



  const filterData = async () => {
    try {

      const res = await getFilterDate(formatDate(startDate), formatDate(endtDate))
      setLoad(false)
      //SEPARAR EL MES Y TOTAL
      let sales = [];
      Object.keys(res).forEach((a, i) => {
        if (res[a].estado === 'finalizado') {
          sales.push({
            date: res[a].start.substring(0, 7),
            total: res[a].servicio.costo
          });
        }
      })

      //ordenar las fechas 
      sales.sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
      })
      //TOTAL DEL MES REPETIDO
      //let resul = sales.reduce((r, o) => (r[o.date] ? (r[o.date].total += o.total) : (r[o.date] = { ...o }), r), {})
      let resul = sales.reduce((r, o) => {
        if (r[o.date]) {
          r[o.date].total += o.total;
        } else {
          r[o.date] = { ...o };
        }
        return r;
      }, {});

      //AGREGAR LOS DATOS AL GRAFICO
      const data = [];
      Object.keys(resul).forEach((a) => {
        data.push({ date: resul[a].date, total: resul[a].total })
      })
      setFilterReservations(data)
      setLoad(true)

    } catch (error) {
      console.log('Error al obtener los datos :', error);
    }
  }

  const options = {
    xaxis: {
      categories: x

    },
    colors: ['#010204']
  };

  const series = [
    {
      name: "Monto Servicios",
      data: y
    }
  ];


  useEffect(() => {

    let y = []
    let x = []
    let aux = 0;
    Object.keys(filterReservations).forEach((a) => {
      aux += filterReservations[a].total
      y.push(filterReservations[a].total)
      x.push(filterReservations[a].date)
    })
    setX(x)
    setY(y)
    setTotal(aux)

  }, [filterReservations])

  return (
    <div >
      <section className="content ">
        <br />
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box">
                <span className="info-box-icon bg-info elevation-1"><i className="fas fa-person" /></span>
                <div className="info-box-content">
                  <span className="info-box-text">Clientes</span>
                  <span className="info-box-number">
                    {clients.length > 0 ? clients.length : <i className="fa fa-spinner fa-spin fa-3x" />}
                  </span>
                </div>
                {/* /.info-box-content */}
              </div>
              {/* /.info-box */}
            </div>
            {/* /.col */}
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-thumbs-up" /></span>
                <div className="info-box-content">
                  <span className="info-box-text">Servicios</span>
                  <span className="info-box-number">
                    {services.length > 0 ? services.length : <i className="fa fa-spinner fa-spin fa-3x" />}
                  </span>
                </div>
                {/* /.info-box-content */}
              </div>
              {/* /.info-box */}
            </div>
            {/* /.col */}
            {/* fix for small devices only */}
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-success elevation-1"><i className="fas fa-users" /></span>
                <div className="info-box-content">
                  <span className="info-box-text">Estilistas</span>
                  <span className="info-box-number">
                    {stylist.length > 0 ? stylist.length : <i className="fa fa-spinner fa-spin fa-3x" />}
                  </span>
                </div>
                {/* /.info-box-content */}
              </div>
              {/* /.info-box */}
            </div>
            {/* /.col */}
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-shopping-cart" /></span>
                <div className="info-box-content">
                  <span className="info-box-text"> Total </span>
                  <span className="info-box-number">
                    {formatNumber(total)}
                  </span>
                </div>
                {/* /.info-box-content */}
              </div>
              {/* /.info-box */}
            </div>
            {/* /.col */}
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 col-md-6">
              <div className="card">
                <div className="card-header">
                  <div className="cad-title">
                    <h5 className="card-title"><b>Cumpleaños de hoy </b></h5>
                  </div>
                  <div className="card-body">
                    <div>
                      <Table striped bordered hover size="sm" responsive>
                        <thead>
                          <tr>
                            <th>Cliente</th>
                            <th>Cumpleaños</th>
                            <th>Teléfono</th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {

                            clients.map((client, index) => {
                              const date = dayjs(client.fecha)
                              const birthday = date.date() === today.date() && date.month() === today.month();
                              if (birthday) {
                                const fechaFormateada = date.format('DD/MM/YYYY');
                                const link = "https://wa.me/" + client.telefono
                                return (
                                  <tr key={index} >
                                    <td> {client.nombre} </td>
                                    <td> {fechaFormateada} </td>
                                    <td>  {client.telefono} </td>
                                    <td className=' text-white'style={{ backgroundColor: "#25D366", padding: "10px" }}  >
                                      <a href={link} target='blank' > <i className='fa-brands fa-whatsapp' style={{ color: 'white' }}  ></i>  </a> <small>Notificar</small>
                                    </td>
                                  </tr>
                                )
                              }
                            })
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6">
              <div className="card">
                <div className="card-header">
                  <div className="cad-title">
                    <h5 className="card-title"> <b> Clientes Cortes </b> </h5>
                  </div>
                  <div className="card-body">
                    <div>
                      <Table striped bordered hover size="sm" responsive>
                        <thead>
                          <tr>
                            <th>Cliente</th>
                            <th># Servicio</th>
                            <th>Teléfono</th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {

                            clients.map((client, index) => {
                              if (client.servicio >= 9) {
                                const link = "https://wa.me/" + client.telefono
                                return (
                                  <tr key={index} >
                                    <td> {client.nombre} </td>
                                    <td> {client.servicio} </td>
                                    <td>  {client.telefono} </td>
                                    <td className=' text-white'style={{ backgroundColor: "#25D366", padding: "10px" }} >
                                      <a href={link} target='blank' > <i className='fa-brands fa-whatsapp' style={{ color: 'white' }}  ></i>  </a> <small>Notificar</small>
                                    </td>
                                  </tr>
                                )
                              }
                            })
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title text-center"><b>Reporte de ventas mensuales</b></h5>
                  <div className="card-body">
                    <br />
                    <Row>
                      <Col className="col-12 col-sm-4 col-md-4" >
                        <Row>
                          <Col className='col-6' >
                            <p><b> Fechas : <br />  <small> {startDate.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' })} <br /> {endtDate.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' })}</small>
                              <br />  Total : {formatNumber(total)} </b>  </p>
                          </Col>
                          <Col className='col-6' >
                            <Button onClick={() => filterData()}  >Consultar</Button>
                          </Col>
                        </Row>
                        <div >
                          {
                            load ? (
                              <DateRange
                              ranges={[selectionRange]}
                              onChange={handleSelect}
                            />
                            ) :(<i className="fa fa-spinner fa-spin fa-3x" />)
                          }

                        </div>
                      </Col>
                      <Col>
                        <Chart options={options} series={series} type="area" />

                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Dashboard