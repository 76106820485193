import { urlAxios } from '../utils/interceptor';

export const getServices = async () => {
    try {
        const response = await urlAxios.get("servicios.json");
        for (let key in response.data) {
                response.data[key].id = key;
        }
        return Object.values(response.data).reverse();
    } catch (error) {
        throw error;
    }
};


export const postServices = async (datos) => {
    try {
        const response = await urlAxios.post(`servicios.json?auth=${localStorage.getItem('token')}`, datos);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateServices = async (id, data) => {

    try {
        const response = await urlAxios.patch(`servicios/${id}.json?auth=${localStorage.getItem('token')}`,data)
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteServices = async (id) => {
    try {
        const response = await urlAxios.delete(`servicios/${id}.json?auth=${localStorage.getItem('token')}`);
        return response;
    } catch (error) {
        throw error;
    }
};
