import React, { useEffect, useState } from 'react'

import Carousel from 'react-bootstrap/Carousel';
import { getCategories } from '../services/apiCategories';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';



function Category() {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await getCategories();
                const categoriesArray = Object.values(response);
                setCategories(categoriesArray);
            } catch (error) {
                console.log('Error al obtener los servicios');
            }
        };
        getData();
    }, []);



    return (
        <section id='services' className='block works-block' style={{ overflowX: 'hidden' }}  >

            <Container fluid  >
                <div className="title-holder">
                    <h2>SERVICIOS</h2>
                    <div className="subtitle">ASESORÍA  CALIDAD  COMODIDAD </div>
                </div>
            </Container>
            <h2 className='text-center' > - LO MÁS POPULAR -</h2>
            
            <Row>
                <Carousel style={{ width: '100%' }}>
                    {categories.map(category => (
                        <Carousel.Item key={category.id} interval={2000} >
                            <img
                                src={category.foto}
                                className="d-block w-100"
                                alt={category.nombre}
                                style={{ maxHeight: '400px', objectFit: 'cover' }}
                            />
                            <Carousel.Caption>
                                <h1  style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} >{category.nombre}</h1>
                            <Link className='btn btn-primary text-center' to="/services" > Agendar Ahora </Link>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Row>
            <h2 className='text-center' > - RESERVAR  AHORA -</h2>
        </section>
    )
}

export default Category