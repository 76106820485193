import React, { useEffect, useState } from 'react'
import HeaderA from './HeaderA'
import SliderNavA from './SliderNavA'
import FooterA from './FooterA'
import { Outlet, useNavigate } from 'react-router-dom';
import Pague404 from '../../components/Pague404'
import { userLogin } from '../../services/apiLogin';



function Admin() {
    const [guards, setGuards] = useState('')
    const navigate = useNavigate();


    useEffect( () => {

        validator()
    } , [] )

    const validator = async() => {
        try {
            let body = {
                idToken : localStorage.getItem('token')
            }
            const res =  await userLogin(body)
            if (res.status === 200) {
                setGuards(localStorage.getItem('token'))
            }
        } catch (error) {
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            navigate('/login')
        }
    }

    return (
        guards ? (<div className='wrapper'>
            <HeaderA />
            <SliderNavA />
            <div className="content-wrapper">
                <Outlet />
            </div>
            <FooterA />
        </div>) : ( <Pague404 />  )

    );
}

export default Admin