import React from 'react'
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import Teams from '../components/Teams';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Category from './Category';
import ServicesSlider from './ServicesSlider';


function Home() {
    return (
        <div  >
            <header id='header' className='color-App' >
                <Header />
            </header>
            <main>
                <Hero />
                <About />
                <Category />
                <ServicesSlider />
                <Teams />
                <Testimonials />
                <Contact />
            </main>
            <footer id='footer' >
                <Footer />
            </footer>
        </div>

    )
}

export default Home