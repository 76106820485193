import { urlAxios } from '../utils/interceptor';




export const getCategories = async () => {
    try {
        const response = await urlAxios.get("categorias.json");
        for (let key in response.data) {
            response.data[key].id = key;
        }
        return Object.values(response.data).reverse()
    } catch (error) {
        throw error;
    }
};


export const postCategories = async (datos) => {
    try {
        const response = await urlAxios.post(`categorias.json?auth=${localStorage.getItem('token')}`, datos);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateCategories = async (id, data) => {

    try {
        const response = await urlAxios.patch(`categorias/${id}.json?auth=${localStorage.getItem('token')}`,data)
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteCategories = async (id) => {
    try {
        const response = await urlAxios.delete(`categorias/${id}.json?auth=${localStorage.getItem('token')}`);
        return response;
    } catch (error) {
        throw error;
    }
};