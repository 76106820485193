import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { alertResponseYesNo, alertTime, deleteFile } from '../../../functions/helperFunctions';
import { deleteCategories, getCategories } from '../../../services/apiCategories';
import CategoryDialog from '../../Dialogs/CategoryDialog';

function CategoryA() {
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);

  const columns = [
    { name: "nombre", label: "Nombre", options: { filter: true, sort: true, } },
    {
      name: "foto", label: "Imágen", options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <img src={value} width={50} height={50} alt='Categorias' />
          )
        }
      }
    },
    {
      name: "acciones",
      label: "ACCIÓN",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (value) => {
          return (
            <>
              <Button variant="warning" onClick={() => openDialog(categories[value])} >
                <i className='fa-solid fa-edit'  ></i>
              </Button>
              &nbsp;
              <Button variant="danger" >
                <i className='fa-solid fa-trash' onClick={() => deleteData(categories[value])}  ></i>
              </Button>
            </>
          );
        },
      },
    },
  ];

  const handleClose = (action) => {
    setShow(false)
    if (action === 'accept') {
      getData()
    }
  };


  const openDialog = (data) => {
    setShow(true)
    setData(data)
  }

  const deleteData = async (data) => {

    const response = await alertResponseYesNo(
                                              'Advertencia: Puede afectar a sus servicios', 
                                              '¿Está seguro de que desea eliminar la categoria? ',
                                              'question');

    if (response.isConfirmed) {
      try {
        const deleteResponse = await deleteCategories(data.id);
        await deleteFile(data.imagen)
        if (deleteResponse.statusText === "OK") {
          alertTime('OK', 'Categoria elminado con éxito', 'success')
          getData()
        } else {
          alert('Error', 'Intente de nuevo', 'error');
        }
      } catch (error) {
        console.error('Error al eliminar la categoria:', error);
        alert('Error', 'Intente de nuevo', 'error');
      }
    }


  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getCategories();
      setCategories(response)
    } catch (error) {
      console.log('Error al obtener las categorias : ', error)
    }
  }

  return (
    <>
      <Container>
        <h2>Listado de categorias </h2>
      </Container>
      <Container className='m-3'>
        <Row>
          <Col sm={12} lg={4} >
            <Button className='w-100' onClick={() => openDialog(null)} >
              <i className='fas fa-plus-circle' > </i> Agregar
            </Button>
          </Col >
        </Row>
      </Container>
      <Container>
        <MUIDataTable
          data={categories}
          columns={columns}
          options={{
            selectableRows: "none",
            download: false,
            viewColumns: false,
            responsive: 'standard',
            elevation: 5,
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 10, 20]
          }}
        />
      </Container>
      < CategoryDialog show={show} handleClose={handleClose} data={data} />

    </>
  )
}

export default CategoryA