import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer';
import NavBarSimple from './NavBarSimple';
import Container from 'react-bootstrap/Container';
import { Form, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import 'react-datepicker/dist/react-datepicker.module.css'
import { getServices } from '../services/apiServices';
import { getClients } from '../services/apiClient';
import { getEstylist } from '../services/apiEstylis';
import { postReserve } from '../services/apiReserve';
import Select from 'react-select'
import { getFilterDateNumber } from '../services/apiReserve';
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import Card from 'react-bootstrap/Card';
import { formatNumber, alert, alertResponse } from '../functions/helperFunctions';
import { useNavigate, useParams } from 'react-router-dom';
import { postClients } from '../services/apiClient';


const hours = [6,7,8,9,10, 11, 12, 13, 14, 15, 16, 17, 18, 19,20]


function Reserve() {

    const { data } = useParams();
    const parsedData = JSON.parse(data);
    const [stylist, setStylist] = useState([]);
    const [clients, setClients] = useState([]);
    const [services, setServices] = useState([]);
    const [stylistSelect, setstylistSelect] = useState(null);
    const [date, setDate] = useState('');
    const [availableHours, setAvailableHours] = useState([])
    const [selectedService, setSelectedService] = useState(null);
    const [selectedHours, setSelectedHour] = useState(null);
    const [loadHours, setLoadHours] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {


        const getStylist = async () => {
            try {
                const response_ = await getEstylist();
                const stylistArray = Object.values(response_);
                setStylist(stylistArray);
                setClients(await getClients())
            } catch (error) {
                console.log('Erro al  obtener los datos de los estilistas : ', error);
            }
        }

        const getServices_ = async () => {
            try {
                const response = await getServices();
                const servicesArray = Object.values(response);
                const selectServices = [];
                servicesArray.map(s => {
                    const select = { label: s.nombre, value: s };
                    selectServices.push(select);
                })
                setServices(selectServices);
            } catch (error) {
                console.log('Error al obtener los datos de los servicios :', error);
            }
        }
        getServices_();
        getStylist();

    }, [])


    useEffect(() => {
        if (parsedData && parsedData.servicio && services.length > 0) {
            const selectedOption = services.find(service => service.label === parsedData.servicio);
            setSelectedService(selectedOption)
        }
    }, [services]);

    useEffect(() => {

        if (parsedData && parsedData.estilista && stylist.length > 0) {
            const stylistOption = stylist.find(s => s.id === parsedData.estilista)

            setstylistSelect({ label: stylistOption.nombre, value: stylistOption })
        }

    }, [stylist])


    const handleSelectServices = (e) => {
        setSelectedService(e)
    }

    const handleSelectHour = ({ value }) => {
        setSelectedHour(value)
    }

    const handleSelectStylist = (e) => {
        setstylistSelect(e)
        apiServer(date, e.value)
    }


    const selectDate = (event) => {
        const inputDate = event.target.value;
        setDate(inputDate)
        if (inputDate && stylistSelect) {
            apiServer(inputDate, stylistSelect.value)
        }
    }

    const filterHours = (arrayHours, start, end) => {
        const horaInicial = dayjs(start);
        const horaFinal = dayjs(end);

        const horasRestantes = [];

        // Itera sobre cada hora dentro del rango
        for (let i = 0; i < arrayHours.length; i++) {
            const horaActual = arrayHours[i];
            // Verifica si la hora actual está fuera del rango
            if (horaInicial.hour() > horaActual || horaActual >= horaFinal.hour()) {
                // Agrega la hora actual a las horas restantes
                horasRestantes.push(horaActual);
            }
        }

        return horasRestantes;
    }

    const apiServer = (date_, stylist_) => {
        if (date_ === '' || stylist_ === '') {
            return;
        }

        const updateAvailableHours = async () => {
            try {
                setLoadHours(true)
                const response = await getFilterDateNumber(date_, stylist_.telefono);
                let updatedHours = [...hours];

                if (response.length > 0) {
                    response.forEach((h) => {
                        updatedHours = filterHours(updatedHours, h.start, h.end);
                    });
                }

                // Actualizar el estado de las horas disponibles
                setAvailableHours(updatedHours);
            } catch (error) {
                console.log('Ha ocurrido un error:', error);
            }
        };

        updateAvailableHours();
        setLoadHours(false)

    };


    const sendData = (event) => {

        event.preventDefault();
        if (selectedService.value === null || selectedHours === null || stylistSelect.value === '') {
            alert('llene todos los campos')
            return
        }

        let dateStart = dayjs(event.target[4].value + 'T' + selectedHours + ':00:00')
        let dateEnd = dateStart.add(selectedService.value.tiempo, 'hour').format('YYYY-MM-DDTHH:mm:ss')
        let data = {
            start: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
            end: dateEnd,
            cliente: {
                nombre: event.target[1].value,
                telefono: parseInt(event.target[2].value),
            },
            servicio: selectedService.value,
            estilista: stylistSelect.value,
            estado: 'agendado'
        }


        requestPostReserve(data);
    }


    const requestPostReserve = async (data) => {
        try {

            let cliente = clients.find(c => c.telefono === data.cliente.telefono)
            if (!cliente) {
                //CAPTURA DE DATOS
                let cl = {
                    nombre: data.cliente.nombre,
                    telefono: data.cliente.telefono,
                    fecha: '',
                    servicio: 0,
                    descripcion: '',
                }
                await postClients(cl)
            }
            let res = await postReserve(data);
            if (res.statusText === "OK") {
                alertResponse('Agendamiento', 'Cita reservada con éxito', 'success')
                    .then(res => {
                        if (res.isConfirmed) {
                            navigate(`/reservationView/${data.cliente.telefono}`)
                        }
                    })
            } else {
                alert('Error', 'Intente nuevamente por favor', 'error')
            }
        } catch (error) {
            alert('Error', 'Error al hacer la solicitud:', 'error')
            console.log(error)
        }
    };

    const formatHour = (hour) => {
        const date = new Date();
        date.setHours(hour); // Establecer solo la hora, los minutos y segundos serán 0 por defecto

        // Formatear la hora usando Day.js
        const formattedHour = dayjs(date).format('h A');
        return formattedHour;
    }

    return (
        <div >
            <NavBarSimple />
            <section className='block contact-block p-1'  >
                <Container fluid >
                    <div className="title-holder">
                        <h2> RESERVAR CITA </h2>
                        <div className="subtitle"> Confia en profecionales de la belleza  </div>
                    </div>
                    <Form className="contact-form" onSubmit={sendData} >
                        <Row>
                            <Col sm={4}    >

                                {
                                    selectedService != null ? (
                                        <Card className='p-0 overflow-hidden h-10 shadow ' style={{ height: '350px' }} >
                                            <div className='overflow-hidden rouded p-0 bg-light' >
                                                <Card.Img variant="top" src={selectedService.value.foto} style={{ height: '200px', objectFit: 'cover' }} alt="Service" />
                                            </div>
                                            <Card.Body  >
                                                <Card.Title className='display-6' >{formatNumber(selectedService.value.costo)}</Card.Title> 
                                                <Card.Text>
                                                <p> <h3 >{selectedService.value.nombre}</h3> </p>
                                                </Card.Text>
                                                <Card.Text>
                                                    <strong>Tiempo:</strong> {selectedService.value.tiempo > 1? selectedService.value.tiempo + ' horas' :selectedService.value.tiempo + ' hora' } 
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    ) : <div className="spinner text-center">
                                        <i className="fa fa-spinner fa-spin fa-3x"></i>
                                        <p>Selecione un servicio</p>
                                    </div>
                                }

                            </Col>

                            <Col sm={4}    >
                                <Form.Group controlId="formGroupServicio"  >
                                    <Form.Label class="fw-bold" >Servicio</Form.Label>
                                    <Select
                                        options={services}
                                        onChange={handleSelectServices}
                                        value={selectedService}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formGroupNombre">
                                    <Form.Label class="fw-bold" >Nombre</Form.Label>
                                    <Form.Control type='text' required />
                                </Form.Group>

                                <Form.Group controlId="formGroupTelefono">
                                    <Form.Label class="fw-bold" >Teléfono</Form.Label>
                                    <Form.Control type='number' required />
                                </Form.Group>

                            </Col>

                            <Col sm={4}    >
                                <Form.Group controlId="formGroupEstilista" required >
                                    <Form.Label class="fw-bold"  >Estilista</Form.Label>
                                    <Select
                                        options={stylist.map(est => ({ label: est.nombre, value: est }))}
                                        value={stylistSelect}
                                        onChange={handleSelectStylist}
                                    />
                                </Form.Group>
                                <span className='text-danger' > {stylistSelect ? stylistSelect.value.descripcion : ''} </span>

                                <Form.Group controlId="formGroupFecha"  >
                                    <Form.Label class="fw-bold" >Fecha</Form.Label> <br />
                                    <Form.Control type='date' className='w-100' value={date} onChange={selectDate} required />
                                </Form.Group>


                                <Form.Group controlId="formGroupHorario" required>
                                    <Form.Label class="fw-bold" >Horario</Form.Label>
                                    {
                                    loadHours ? (<div className="spinner text-center">
                                        <i className="fa fa-spinner fa-spin fa-3x"></i>
                                        <p>Selecione un servicio</p>
                                    </div>) : (                                    <Select
                                        options={availableHours.map(hrs => ({ label: formatHour(hrs), value: hrs }))}
                                        onChange={handleSelectHour}
                                    />)
                                }

                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="btn-holder ">
                            <Button type='submit' > AGENDAR </Button>
                        </div>
                    </Form>
                </Container>
                <p className='  text-center' >
                    ¿Tienes una duda o problema con tu reserva?, comunícate con nosotros al <a href="https://wa.link/cwu06x" target='blank' > +1(929)-377-4244 </a>
                    </p>
            </section>
            <footer id='footer' className='m-0'>
                <Footer />
            </footer>
        </div>
    )
}

export default Reserve