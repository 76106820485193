import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { alert, alertTime, deleteFile, uploadFile, validateImage } from '../../functions/helperFunctions';
import Select from 'react-select'
import { getCategories } from '../../services/apiCategories';
import { updateServices,postServices } from '../../services/apiServices';


function ServiceDialog({ show, handleClose, data }) {

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [costo, setCosto] = useState('');
    const [tiempo, setTiempo] = useState(0);
    const [offert, setOffer] = useState('');

    const [categories, setCategories] = useState([]);
    const [categorySelect, setCategorySelect] = useState(null);

    //VARIABLES PARA EL MANEJO DE LAS IMAGENES
    const [image, setImage] = useState(null);
    const [update, setUpdate] = useState(false);
    const [changeImage, setChangeImage] = useState('')
    const [file, setFile] = useState(null);
    const [load, setLoad] = useState(true);


    useEffect(() => {

        getCategoriesData()
    }, []);


    useEffect(() => {
        if (show && data != null) {
            setId(data.id)
            setName(data.nombre)
            setCosto(data.costo)
            setTiempo(data.tiempo)
            setOffer(data.oferta)

            setChangeImage(data.foto)
            setImage(data.foto)
            setUpdate(true)

            var cat = categories.find(c => c.nombre === data.categoria)
            cat? setCategorySelect({ label: cat.nombre, value: cat }) :  setCategorySelect({ label: 'Selecione categoria', value: '' })

        } else {
            setId('')
            setName('')
            setCosto('')
            setTiempo(0)
            setOffer('')
            setFile(null)
            setImage(null)
            setUpdate(false)
            setChangeImage('')

        }
    }, [show, data]);


    const uploadPhoto = async (e) => {
        const result = await validateImage(e)
        if (result) {
            const file = e.target.files[0];
            setFile(result)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            if (file) {
                reader.readAsDataURL(file);
            }
            setFile(file)
        }
    }


    const getCategoriesData = async () => {
        const response = await getCategories();
        setCategories(response)
    }

    const saveData = async (e) => {

        e.preventDefault();
        if(categorySelect === null) {  alert('Llene todos los campos'); return}
        setLoad(false)
        try {

            //PROCESO DE GUARDADO DE IMAGEN
            var url = changeImage;
            if (file != null) {
                if (!update) {
                    url = await uploadFile(file, 'services')
                } else {
                    url = await uploadFile(file, 'services')
                    await deleteFile(changeImage)
                }
            }

            //CAPTURA DE DATOS
            const data = {
                nombre: name,
                costo:parseFloat(costo),
                tiempo:parseInt(tiempo),
                oferta:offert,
                categoria:categorySelect.value.nombre,
                foto:url
            }

            var res;
            update ? res = await updateServices (id, data) : res = await postServices(data);

            if (res) {
                alertTime('Exito', 'Servicio ingresado correctamente', 'success')
                setLoad(true)
                handleClose('accept')
            }

        } catch (error) {
            alert('Error: Intente otra vez')
            setLoad(true)

        }

    }


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton >
                <Modal.Title> {data != null ? 'Editar ' : 'Nuevo '} servicio  </Modal.Title>
            </Modal.Header>
            {
                load ?
                    (
                        <Modal.Body>
                            <Form onSubmit={(e) => saveData(e)} >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        autoFocus
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Costo</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={costo}
                                        onChange={(e) => setCosto(e.target.value)}
                                        required

                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Tiempo</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={tiempo}
                                        onChange={(e) => setTiempo(e.target.value)}
                                        required

                                    />
                                </Form.Group>

                                <Form.Group controlId="formGroupCategoria" required >
                                    <Form.Label className="fw-bold"  >Categoria</Form.Label>
                                    <Select
                                        options={categories.map(cat => ({ label: cat.nombre, value: cat }))}
                                        value={categorySelect}
                                        onChange={(e) => setCategorySelect(e)}
                                    />
                                </Form.Group>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Promoción</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={offert}
                                        onChange={(e) => setOffer(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Foto</Form.Label>
                                    <Form.Control
                                        type='file'

                                        onChange={e => uploadPhoto(e)}
                                    />
                                </Form.Group>
                                {image && (
                                    <div>
                                        <p>Imagen cargada:</p>
                                        <img src={image} alt="Imagen cargada" style={{ maxWidth: '100%' }} />
                                    </div>
                                )}

                                <Modal.Footer>
                                    <Button variant="primary" type='submit'>
                                        Guardar
                                    </Button>
                                    &nbsp;
                                    <Button variant="secondary" type='button' onClick={() => handleClose('cancel')}>
                                        Cancelar
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal.Body>

                    ) : (
                        <div className="spinner text-center">
                            <i className="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    )
            }

        </Modal>
    )
}

export default ServiceDialog