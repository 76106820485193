import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}clientes.json`;

export const getClients = async () => {
    try {
        const response = await axios.get(API_URL);
        for (let key in response.data) {
            response.data[key].id = key;
        }
        return Object.values(response.data).reverse();
    } catch (error) {
        throw error;
    }
};


export const postClients = async (datos) => {
    try {
        const response = await axios.post(API_URL, datos);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateClients = async (id, data) => {

    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}clientes/${id}.json`,data)
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteClients = async (id) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}clientes/${id}.json`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const patchClientService = async (id,value) => {
    try {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}clientes/${id}.json`,value);
        return response;
    } catch (error) {
        throw error;
    }
}
