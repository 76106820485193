import Swal from "sweetalert2"
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase/config";
import { v4 } from "uuid";


export function formatNumber(number) {
    return new Intl.NumberFormat(
        "en-US",
        {
            style: 'currency',
            currency: 'USD'
        }
    ).format(number)
}


export function alertResponse(_title, _text, _icon) {

    return Swal.fire({
        title: _title,
        text: _text,
        icon: _icon
    });
}

export function alertResponseYesNo(_title, _text, _icon) {

    return Swal.fire({
        title: _title,
        text: _text,
        icon: _icon,
        showDenyButton: true,
        confirmButtonText: 'Sí',
        denyButtonText: 'No'
    });
}

export function alert(_title, _text, _icon) {
    Swal.fire({
        title: _title,
        text: _text,
        icon: _icon
    });
}

export function alertTime(_title, _text, _icon) {
    Swal.fire({
        title: _title,
        text: _text,
        icon: _icon,
        timer: '1000',
        showConfirmButton:false
    });
}


//AGREGAR IMAGENES EN FIREBASE 
export async function uploadFile(file, folderName) {
    const storageRef = ref(storage, `images/${folderName}/${v4()}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
}


//ELIMINAR UNA IMAGEN 
export async function deleteFile(imageUrl) {
    try {
        const pathStartIndex = imageUrl.indexOf('/images/');
        const pathEndIndex = imageUrl.indexOf('?');
        const filePath = imageUrl.substring(pathStartIndex, pathEndIndex);
        // Obtener una referencia al archivo usando la ruta obtenida
        const imageRef = ref(storage, filePath);
        // Eliminar el archivo
        await deleteObject(imageRef);

    } catch (error) {
        console.log('ERROR AL ELIMINAR LA IMAGEN', error)
    }
}


export async function validateImage(e) {
    return new Promise(resolve => {
        const image = e.target.files[0];

        /*===================
        Validar el Formato 
        ====================*/

        if (!image || (image.type !== "image/jpeg" && image.type !== "image/png")) {
            alert("ERROR: la imagen debe estar en formato PNG | JPG");
            return;

            /*===============================
            Validar el tamaño, máximo 2 Megas
            ================================*/
        } else if (image.size > 2000000) {
            alert("ERROR: Tamaño máximo de la imagen: 2MB");
            return;
        }

        /*===============================
        Mostrar la imagen temporal
        ================================*/
        else {
            resolve(image)
        }
    });
}

export function formatDate (date) {
    return `${date.getFullYear()}-${('0'+(date.getMonth()+1)).slice(-2)}-${('0'+date.getDate()).slice(-2)}T00:00:00`;
} 
