import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import Container from 'react-bootstrap/Container';
import { getServices } from '../services/apiServices';

import ServiceItem from './ServiceItem';


import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'

function ServicesSlider() {

  const [services, setServices] = useState([])

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getServices();
        const responseArray = Object.values(response)
        setServices(responseArray)
      } catch (error) {
        console.log('Error al obtener los servicios : ', error)
      }
    }

    getData();
  }, []);

  return (
    <Container className='py-4 px-4 justify-contentcenter ' >
      <Swiper
        freeMode={true}
        grabCursor={true}
        className='mySwiper'
        breakpoints={{
          0: { slidesPerView: 1, spaceBetween: 10 },
          480: { slidesPerView: 2, spaceBetween: 10 },
          768: { slidesPerView: 3, spaceBetween: 15 },
          1024: { slidesPerView: 4, spaceBetween: 15 },
          1280: { slidesPerView: 5, spaceBetween: 30 },
        }}
        modules={[Navigation, Pagination, A11y, Autoplay]}
        navigation
        loop={true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        onSwiper={(swiper) => {}}
        onSlideChange={() => {}}
      >
        {
          services.map((service) => {
            return (
              <SwiperSlide  key={service.id} >
                < ServiceItem 
                  data={{ foto: service.foto, costo: service.costo, nombre: service.nombre, tiempo: service.tiempo, oferta:service.oferta }} />
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </Container>
  )
}

export default ServicesSlider