import React from 'react'
import Container from 'react-bootstrap/Container';

function Contact() {
    return (
        <section id='contact' className='block contact-block' >
            <Container fluid >
                <div className="title-holder">
                    <h2>CONTACTO</h2>
                    <div className="subtitle">Comunicate con nosotros</div>
                </div>
            </Container>
                <div className="google-maps">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.9387599884435!2d-73.91600771488459!3d40.69734636477306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25c228d823009%3A0xdd905abbadf04795!2s1377%20Madison%20St%2C%20Brooklyn%2C%20NY%2011237%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sec!4v1713287492895!5m2!1ses-419!2sec" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            <Container fluid >
                <div className="contact-info">
                    <ul>
                        <li> <i className='fas fa-clock' />
                        <strong>ATENCIÓN</strong>
                        <p>Lunes a Sabado 10:00 am - 8:00 pm</p>Domingos 10:00 am a 5:00 pm
                        </li>
                        <li> <i className='fas fa-phone' />
                        <strong>COMUNICATE </strong>
                        <p>+1(929)-377-4244</p> 
                        </li>
                        <li> <i className='fas fa-map-marker-alt' />
                        <strong>VISITANOS</strong>
                        <p> 1377 Madison St Brooklyn NY 11237</p>
                        </li>
                        
                    </ul>
                </div>
            </Container>
        </section>
    )
}

export default Contact