import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getServices } from '../services/apiServices';
import ServiceItem from './ServiceItem';
import NavBarSimple from './NavBarSimple'
import Footer from './Footer'

function Services() {

    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getServices(); // Llamar a la función getEstylist para obtener los estilistas
                const servicesArray = Object.values(response); // Convertir el objeto en un array
                setServices(servicesArray); // Almacenar los estilistas en el estado
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener los servicios:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <>
            <NavBarSimple />
            <section id='services' className='block works-block' >
                <Container fluid >
                    <div className="title-holder">
                        <h2>SERVICIOS</h2>
                        <div className="subtitle">ASESORíA  CALIDAD  COMODIDAD </div>
                    </div>

                    {
                        loading ? (
                            <div className="spinner text-center">
                                <i className="fa fa-spinner fa-spin fa-3x"></i>
                            </div>
                        ) : (
                            <Row className='portfoliolist' >
                                {
                                    services.map(service => {

                                        return (
                                            <Col sm={3} key={service.id}>
                                                < ServiceItem
                                                    data={{ foto: service.foto, costo: service.costo, nombre: service.nombre, tiempo: service.tiempo , oferta : service.oferta }} />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        )
                    }


                </Container>
            </section>
            <footer id='footer' >
                <Footer />
            </footer>
        </>

    )
}

export default Services