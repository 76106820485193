import React, { useEffect, useState } from 'react'
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import Select from 'react-select'
import { getReservations } from '../../services/apiReserve'
import { getEstylist } from '../../services/apiEstylis'
import { useParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import CitasDialog from '../Dialogs/CitasDialog'

dayjs.locale('es')


function Diary() {
    const localizer = dayjsLocalizer(dayjs)
    const [reservations, setReservations] = useState([]);
    const [stylist, setStylist] = useState([]);
    const [events, setEvents] = useState([]);
    const [user, setUser] = useState(null)
    const { number } = useParams();
    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [admin, setAdmin] = useState(false);



    const handleClose = (action) => {
        setShow(false)
        if (action === 'accept') { window.location.reload(); }
    };


    const openDialog = (data) => {
        setShow(true)
        setData(data)
    }


    useEffect(() => {
        getReservation();
        getStylist()
        localStorage.getItem('token') ? setAdmin(true) : setAdmin(false);

    }, [])

    useEffect(() => {
        const reservationFilter = async () => {
            try {
                const response = await getReservations();
        
                if (!response || response.length === 0) {
                    setEvents([]);
                    return;
                }
        
                const filterReservation = response.filter(res => res.estilista.telefono === user.value.telefono);
        
                if (filterReservation.length > 0) {
                    const eventArray = filterReservation.map(ev => ({
                        start: dayjs(ev.start).toDate(),
                        end: dayjs(ev.end).toDate(),
                        title: ev.servicio.nombre,
                        data: ev
                    }));
                    setEvents(eventArray);
                } else {
                    setEvents([]);
                }
            } catch (error) {
                console.error('Error al obtener reservaciones:', error);
                // Maneja el error según sea necesario
            }
        }

        reservationFilter()

    }, [user])

    const getReservation = async () => {
        const response = await getReservations();
        setReservations(response)
    }

    const getStylist = async () => {
        const response = await getEstylist();
        setStylist(response)
        const stylistSelected = response.find(est => est.telefono === parseInt(number))
        if (stylistSelected) { setUser({ label: stylistSelected.nombre, value: stylistSelected }) }

    }

    const handleSelectStylist = (e) => {
        setUser(e)
    }



    const messages = {
        allDay: "Todo el día",
        previous: "Anterior",
        next: "Siguiente",
        today: "Hoy",
        month: "Mes",
        week: "Semana",
        day: "Día",
        agenda: "Agenda",
        date: "Fecha",
        time: "Hora",
        event: "Evento",
        noEventsInRange: "Sin eventos"
    };

    const componente = {
        event: props => {
            const { data } = props.event
            if (data.estado === 'agendado') {
                return <div className=' rounded' style={{ background: '#E82E35' }} >
                    <i className='fas fa-clock m-1' ></i>
                    {props.title}
                </div>
            }
            return <div className='rounded ' style={{ background: 'green' }} >
                <i className='fas fa-check m-1' ></i>
                {props.title}
            </div>
        }
    }

    return (
        <>
            <Container>
                <h2>Mi agenda </h2>
            </Container>

            {
                user ? (
                    <div>
                        <Container>
                            <Row className='m-1 p-1' >
                                <Col sm={4} >
                                    {
                                        admin ? (
                                            <Select
                                                options={stylist.map(est => ({ label: est.nombre, value: est }))}
                                                value={user}
                                                onChange={handleSelectStylist}
                                            />
                                        ) : (<h3> {user ? user.value.nombre : ''} </h3>)
                                    }

                                </Col>
                                <Col sm={4} >
                                    <Link className="btn btn-primary w-100" target='_blank' to={`/reserve/${JSON.stringify({ servicio: '', estilista: '' })}`} >
                                        Agendar </Link>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='w-100' >
                                    <div
                                        style={{
                                            height: '60vh',
                                            width: '81vw'
                                        }}
                                    >
                                        <Calendar className='border border-secondary rounded bg-white'
                                            localizer={localizer}
                                            events={events}
                                            messages={messages}
                                            min={dayjs('2024-10-4T10:00:00').toDate()}
                                            max={dayjs('2024-10-4T21:00:00').toDate()}
                                            components={componente}
                                            onSelectEvent={openDialog}
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <CitasDialog show={show} handleClose={handleClose} data={data} />
                    </div>
                ) : (<h2 className='text-center' > El estilista no existe , verifique su número </h2>)
            }

        </>

    )
}

export default Diary