import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/Amara Logo 1.png';
import { login } from '../../services/apiLogin';
import { alert } from '../../functions/helperFunctions'
import { useNavigate } from 'react-router-dom';

function Login() {


  const [load, setLoad] = useState(true);
  const navigate = useNavigate();


  const loginEvent = async (event) => {
    event.preventDefault();
    setLoad(false);

    try {
      let data = {
        email: event.target[0].value,
        password: event.target[1].value,
        returnSecureToken: true
      }
      const res = await login(data)
      if (res.status === 200) {
        navigate('/admin/dashboard')
        setLoad(true);

      }
    } catch (err) {
      alert('Error', 'Usuario o contraseña incorrectos', 'error')
      setLoad(true);

    }

  }


  return (
    <div>
      <div className="hold-transition login-page login ">
        <div className="login-box" style={{ borderColor: 'transparent' }}>
          {/* /.login-logo */}
          <div className="card card-outline  bg-white   bg-opacity-25 "  >
            <div className="card-header text-center   "  >
              <img
                src={logo}
                height="auto"
                className="align-top"
                alt="Amara Beauty Salon"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="card-body bg-white bg-opacity-10 ">
              <p className="login-box-msg  text-dark"> <strong>INICIAR SESIÓN</strong></p>

              {
                load ? (<form onSubmit={(event) => loginEvent(event)} >
                  <div className="input-group mb-3">
                    <input type="email" className="form-control" placeholder="Correo" required />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope" />
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input type="password" className="form-control" placeholder="Contraseña" required />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button type="submit" className="btn btn-primary btn-block">Iniciar</button>
                    </div>
                    {/* /.col */}
                  </div>
                </form>) : ( <div className='text-center' > <i className="fa fa-spinner fa-spin fa-3x " /> </div>  )
              }
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.login-box */}
      </div>

    </div>
  )
}

export default Login