import React from 'react'

function FooterA() {
    return (
        <footer className="main-footer color-App ">
        <strong>Copyright &copy; 2014  Amara Beauty Salón.</strong>
        Todos los derechos reservados.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 1.0
        </div>
      </footer>
    )
}

export default FooterA