import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
    apiKey: "AIzaSyB7YkNfcR6I88wCsotKbHvdultwD7OdAhc",
    authDomain: "amara-fc483.firebaseapp.com",
    databaseURL: "https://amara-fc483-default-rtdb.firebaseio.com",
    projectId: "amara-fc483",
    storageBucket: "amara-fc483.appspot.com",
    messagingSenderId: "738633628632",
    appId: "1:738633628632:web:9cdf634e1dbcea622b10ad"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

