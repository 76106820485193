import React from 'react'
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';


var testimonialsData = [
    {
        id: 1,
        name: 'ANDY',
        description: '¡Increíble experiencia en este salón! Me hice un corte de pelo y peinado aquí y quedé encantada. El estilista entendió exactamente lo que quería y superó mis expectativas.!',
        designation: 'Manager'
    },
    {
        id: 2,
        name: 'JESSICA',
        description: '¡Mis uñas nunca han lucido tan bien! Opté por una manicura y pedicura aquí y el resultado fue impecable. El equipo utiliza productos de alta calidad y presta atención a cada detalle.¡Recomendaría este salón a cualquiera que busque un servicio de uñas excepcional!',
        designation: 'Accountant'
    },
    {
        id: 3,
        name: 'PAOLA',
        description: '¡Este lugar es mi secreto para una piel radiante! Recientemente disfruté de un tratamiento facial aquí y me encantó. La estilista fue muy profesional y personalizó el tratamiento según mis necesidades.!',
        designation: 'CEO'
    },
    {
        id: 4,
        name: 'DAVID',
        description: '¡Mi cabello nunca se ha visto tan vibrante y saludable! Decidí probar un tratamiento de coloración capilar aquí y estoy encantado con los resultados. La estilista fue muy talentosa y logró el color exacto que estaba buscando.!',
        designation: 'CEO'
    },
    {
        id: 5,
        name: 'MOISES',
        description: '¡Una experiencia de barbería excepcional de principio a fin! Decidí probar este salón para un corte de barbero y no podría estar más satisfecho. Desde el momento en que entré, fui recibido con una cálida bienvenida y un ambiente acogedor. !',
        designation: 'CEO'
    }
]


function Testimonials() {
    return (
        <section id='testimonials' className='testimonials-block' >
            <Container fluid >
                <div className="title-holder">
                    <h2>Reseñas </h2>
                    <div className="subtitle">Lo que opinan nuestros clientes </div>
                </div>
                <Carousel controls={false} >
                    {
                        testimonialsData.map(testimonials => {
                            return (
                                <Carousel.Item  key={testimonials.id}   >
                                    <blockquote style={{ backgroundColor: 'transparent' }} >
                                        <p>{ testimonials.description } </p>
                                        <cite>
                                            <span className='name' > { testimonials.name } </span>
                                            <span> 
                                                <i className=' start fas fa-star' >  </i>
                                                <i className=' start fas fa-star' >  </i>
                                                <i className=' start fas fa-star' >  </i>
                                                <i className=' start fas fa-star' >  </i>
                                                <i className=' start fas fa-star' >  </i>
                                            
                                            </span>
                                        </cite>
                                    </blockquote>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </Container>
        </section>
    )
}

export default Testimonials