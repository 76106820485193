import axios from "axios";

const urlAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

urlAxios.interceptors.request.use(async (config) => {

    if (config.url === process.env.REACT_APP_API_URL_LOGIN || config.url === process.env.REACT_APP_API_URL_REFRESH_TOKEN || config.method === "get") {

        return config
    }

    const token = localStorage.getItem('token')
    const payload = JSON.parse(atob(token.split(".")[1])).exp;
    const tokenExp = new Date(payload * 1000);
    const now = new Date();
    now.setTime(now.getTime() + (15 * 60 * 1000));
    if (tokenExp.getTime() < now.getTime()) {
        await refresh();
    }
    return config
});

const refresh = async () => {
    try {
        let body = {
            "grant_type": "refresh_token",
            "refresh_token": localStorage.getItem('refreshToken')
        }
        const response = await axios.post(process.env.REACT_APP_API_URL_REFRESH_TOKEN, body);
        localStorage.setItem('token', response.data.id_token)
        localStorage.setItem('refreshToken', response.data.refresh_token)
        return response

    } catch (error) {
        throw error;
    }
}

export { urlAxios }