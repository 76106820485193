import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/AdminLTELogo.png';
import { Link, useNavigate } from 'react-router-dom';
import { alertResponseYesNo } from '../../functions/helperFunctions';
import { getEstylist}  from '../../services/apiEstylis'

function SliderNavA() {

  const navigate = useNavigate();
const[number, setNumber] = useState(0)

useEffect( ()  => {
      const estylist  = async () => {
        const res = await getEstylist()
        let {telefono} = res.find(e => e.id === '1')
        setNumber(telefono)
      }
      estylist()
} ,[] )

  // Obtener todos los elementos li dentro de la barra lateral
  const navItems = document.querySelectorAll('.nav-admin .nav-item');

  // Función para cambiar el estado activo
  function setActiveNavItem(clickedItem) {
    // Remover la clase 'active' de todos los elementos li
    navItems.forEach(item => {
      item.classList.remove('active');
    });

    // Agregar la clase 'active' solo al elemento clickeado
    clickedItem.classList.add('active');
  }

  // Añadir evento de clic a cada elemento li
  navItems.forEach(item => {
    item.addEventListener('click', function () {
      setActiveNavItem(this); // 'this' hace referencia al elemento clickeado
    });
  });

  const logOut = async () => {

    const response = await alertResponseYesNo('Salir', '¿Desea salir del sistema?', 'question');

    if (response.isConfirmed) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      navigate('/login')
    }


  }


  return (
    <div>
      <aside className="main-sidebar sidebar-primary elevation-4 nav-admin  ">
        {/* Brand Logo */}
        <Link to="/admin/dashboard" className="brand-link">
          <img src={logo} lt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
          <span className="brand-text  ">Administrador </span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">

          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

              <li className="nav-item">
                <Link to="dashboard" className="nav-link">
                  <i className="nav-icon fas fa-chart-pie" />
                  <p>Estadisticas  </p>
                </Link>
              </li>

              <li className="nav-item">
                <Link to={`diary/${number}`} className="nav-link">
                  <i className="nav-icon fas fa-calendar-alt" />
                  <p> Calendario</p>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="servicesA" className="nav-link">
                  <i className="nav-icon fas fa-wand-magic-sparkles" />
                  <p> Servicios </p>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="categoriesA" className="nav-link">
                  <i className="nav-icon far fa-image" />
                  <p> Categorias</p>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="stylistA" className="nav-link">
                  <i className="nav-icon  fa-solid fa-child-dress" />
                  <p> Estilistas </p>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="clientesA" className="nav-link">
                  <i className="nav-icon fas fa-users" />
                  <p> Clientes </p>
                </Link>
              </li>

              <li className="nav-item">
                <a className="nav-link" onClick={ () => logOut()} >
                  <i className="nav-icon fas fa-right-to-bracket" />
                  <p> Cerrar Sesión </p>
                </a>
              </li>

            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>

    </div>
  )
}

export default SliderNavA