import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { alert, alertTime } from '../../functions/helperFunctions';
import { postClients, updateClients } from '../../services/apiClient';

function ClientDialog({ show, handleClose, data }) {

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [date, setDate] = useState('');
    const [service, setService] = useState('');
    const [description, setDesciption] = useState('');
    const [load, setLoad] = useState(true);
    const [update, setUpdate] = useState(false);


    useEffect(() => {
        if (show && data != null) {
            setId(data.id)
            setName(data.nombre)
            setPhone(data.telefono)
            setDate(data.fecha)
            setService(data.servicio)
            setDesciption(data.descripcion)
            setUpdate(true)


        } else {
            setId('')
            setName('')
            setPhone('')
            setDate('')
            setService('')
            setDesciption('')
            setUpdate(false)

        }
    }, [show, data]);

    const saveData = async (e) => {

        e.preventDefault();
        setLoad(false)
        try {


            //CAPTURA DE DATOS
            const data = {
                nombre: name,
                telefono: parseInt(phone),
                fecha:date,
                servicio:parseInt(service),
                descripcion: description,

            }

            var res ;
            update? res = await updateClients(id,data) : res = await postClients(data);

            if (res) {
                alertTime('Exito', 'Cliente ingresado correctamente', 'success')
                setLoad(true)
                handleClose('accept')
            }

        } catch (error) {
            alert('Error: Intente otra vez')
            setLoad(true)

        }

    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title> {data != null ? 'Editar ' : 'Nuevo '} cliente  </Modal.Title>
            </Modal.Header>
            {
                load ?
                    (
                        <Modal.Body>
                            <Form onSubmit={(e) => saveData(e)} >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        autoFocus
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Teléfono</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                        

                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Número de Servicio</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={service}
                                        onChange={(e) => setService(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Descipción</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={description}
                                        onChange={(e) => setDesciption(e.target.value)}
                                    />
                                </Form.Group>


                                <Modal.Footer>
                                    <Button variant="primary" type='submit'>
                                        Guardar
                                    </Button>
                                    &nbsp;
                                    <Button variant="secondary" type='button' onClick={() => handleClose('cancel')}>
                                        Cancelar
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal.Body>

                    ) : (
                        <div className="spinner text-center">
                            <i className="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                    )
            }

        </Modal>
    )
}

export default ClientDialog